import styled from 'styled-components';
import { FsBannerHero } from '@printi/ds-offset-react-core';
import { media } from '../../utils/styles';

export const ReferralCard = styled(FsBannerHero)`
  margin-top: var(--spacing-2x);
  border-radius: var(--border-radius-md);
  height: 312px;
  min-width: fit-content;

  ${media.lessThan('touch')} {
    min-height: 172px;
    height: 240px;
  }
`;
