'use client';

import {
  BannerHeroBgColor,
  BannerHeroContentType,
  BannerHeroSize,
  DescriptionTag,
  FsButtonMini,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import * as S from './ReferralBanner.styles';

export const ReferralBanner = () => {
  return (
    <S.ReferralCard
      backgroundColor={BannerHeroBgColor.LightGrey}
      size={BannerHeroSize.Auto}
      contentType={BannerHeroContentType.Text}
      contentSlot={
        <>
          <FsDescription tag={DescriptionTag.SPAN}>
            Bônus em Printi Money
          </FsDescription>
          <FsHeading size={HeadingSize.SM} tag={HeadingTag.SPAN}>
            Ganhe R$20 indicando a Printi para um amigo
          </FsHeading>
        </>
      }
      actionSlot={
        <a href="/minha-conta/indicacoes" style={{ textDecoration: 'none' }}>
          <FsButtonMini>Saiba mais</FsButtonMini>
        </a>
      }
    ></S.ReferralCard>
  );
};
