'use client';

import { CartContextProvider } from '@mf/common/contexts/cart/CartContext';
import { DrawerContextProvider } from '@mf/common/contexts/drawer/DrawerContext';
import { ModalContextProvider } from '@mf/common/contexts/modal/ModalContext';
import { PromotionsContextProvider } from '../../contexts/promotions';
import { LayoutContextProvider } from '../Media/Layout';

export const GlobalContexts = ({ children }: { children: React.ReactNode }) => {
  const bffUrl = String(process.env.BFF_API_BASE_URL);

  const payload = { bffUrl };

  return (
    <LayoutContextProvider>
      <CartContextProvider payload={payload}>
        <DrawerContextProvider>
          <ModalContextProvider>
            <PromotionsContextProvider>{children}</PromotionsContextProvider>
          </ModalContextProvider>
        </DrawerContextProvider>
      </CartContextProvider>
    </LayoutContextProvider>
  );
};
