export const DrawerContextDrawer = {
  isOpenDrawerPersonalizationHireDesigner: false,
  openDrawerPersonalizationHireDesigner: () => {},
  closeDrawerPersonalizationHireDesigner: () => {},
  isOpenDrawerPersonalizationPayment: false,
  openDrawerPersonalizationPayment: () => {},
  closeDrawerPersonalizationPayment: () => {},
  isOpenDrawerPersonalizationTemplates: false,
  openDrawerPersonalizationTemplates: () => {},
  closeDrawerPersonalizationTemplates: () => {},
};

export type DrawerContextType = {
  isOpenDrawerPersonalizationHireDesigner: boolean;
  openDrawerPersonalizationHireDesigner: () => void;
  closeDrawerPersonalizationHireDesigner: () => void;
  isOpenDrawerPersonalizationPayment: boolean;
  openDrawerPersonalizationPayment: () => void;
  closeDrawerPersonalizationPayment: () => void;
  isOpenDrawerPersonalizationTemplates: boolean;
  openDrawerPersonalizationTemplates: () => void;
  closeDrawerPersonalizationTemplates: () => void;
};

export type DrawerContextProps = {
  children: React.ReactNode;
};
