'use client';

import { Fragment, ReactNode } from 'react';
import { useLayout } from '../Media/Layout';

type DesktopViewProps = {
  children: ReactNode;
};

export const DesktopView = ({ children }: DesktopViewProps) => {
  const { isDesktop } = useLayout();

  return isDesktop ? <Fragment>{children}</Fragment> : null;
};
