'use client';

import React, { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { CustomSession } from '@mf/common/config/nextAuth/types';

export function UserTracker() {
  let _hsq = [];
  const pathname = usePathname();
  const { data: session } = useSession();
  const { user } = (session as CustomSession) || {};
  const email = useMemo(() => user?.email, [user?.email]);

  if (typeof window !== 'undefined') {
    _hsq = window._hsq = window._hsq || [];
  }

  React.useEffect(() => {
    if (email) {
      _hsq.push(['identify', { email }]);
      _hsq.push(['setPath', pathname]);
      _hsq.push(['trackPageView']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, email]);

  return null;
}
