import { media } from '@mf/common/utils/styles/index';
// eslint-disable-next-line
import styled from 'styled-components';

export const Summary = styled.div`
  box-sizing: border-box;
  display: flex;
  max-width: 553px;
  padding: var(--spacing-5x) var(--spacing-4x);
  flex-direction: column;
  align-items: felx-start;
  width: 100%;
  height: max-content;
  gap: var(--spacing-4x);
  border-radius: var(--border-radius-md);
  border: 1.6px solid var(--elements-border-color);

  div[data-expanded='true'] {
    > div[data-hidden='false'] {
      overflow: visible !important;
    }
  }

  ${media.lessThan('mobile')} {
    gap: var(--spacing-3x);
    border: none;
    padding: 0;
  }
`;

export const TooltipWrapper = styled.div`
  width: 100%;

  > div {
    width: auto !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);

  ${media.lessThan('mobile')} {
    gap: var(--spacing-2_5x);
  }
`;

export const ListContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
  align-self: stretch;
`;

export const ListContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-1x);
  align-self: stretch;
`;
