'use client';

import {
  FsAccordion,
  FsHeading,
  FsInputText,
  FsListContentGhost,
  FsTooltip,
  HeadingSize,
  HeadingTag,
  TooltipPosition,
} from '@printi/ds-offset-react-core';
import React, { Fragment, useState } from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from './Summary.styles';

interface SummaryProps {
  total?: string;
  products?: React.ReactNode[];
  items: React.ReactNode;
  flag?: React.ReactNode;
  button?: React.ReactNode;
  heading: string;
  showCoupon?: boolean;
  onApplyCoupon?: (voucher?: string) => void;
  isLoadingVoucher?: boolean;
  voucherError?: string;
  isDisabledInputCoupon?: boolean;
}

export function Summary({
  total,
  products,
  items,
  heading,
  flag,
  button,
  showCoupon,
  onApplyCoupon,
  isLoadingVoucher,
  voucherError,
  isDisabledInputCoupon,
}: SummaryProps) {
  const { isMobile } = useLayout();
  const [voucher, setVoucher] = useState<string>('');
  const [voucherSupportText, setVoucherSupportText] = useState<string>('');

  const handleChangeVoucher = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length) {
      setVoucherSupportText('');
    }
    setVoucher(value);
  };

  return (
    <S.Summary>
      <FsHeading
        size={isMobile ? HeadingSize.XS : HeadingSize.SM}
        tag={HeadingTag.SPAN}
      >
        {heading}
      </FsHeading>

      {(showCoupon || products) && (
        <S.ContentWrapper>
          {showCoupon && !isDisabledInputCoupon && (
            <FsInputText
              disabled={isLoadingVoucher}
              label="Cupom de desconto"
              placeholder="Insira o cupom"
              showButton
              buttonLabel="Aplicar"
              onChange={handleChangeVoucher}
              onClick={() => {
                if (onApplyCoupon) {
                  if (!voucher.length) {
                    setVoucherSupportText('Insira um cupom válido');
                    return;
                  }
                  setVoucherSupportText('');
                  onApplyCoupon(voucher);
                }
              }}
              error={!!voucherError || !!voucherSupportText}
              supportText={voucherError || voucherSupportText}
            />
          )}

          {showCoupon && isDisabledInputCoupon && (
            <S.TooltipWrapper>
              <FsTooltip
                position={TooltipPosition.Top}
                label="Insira depois de escolher o frete ou ponto de retirada"
              >
                <FsInputText
                  disabled
                  label="Cupom de desconto"
                  placeholder="Insira o cupom"
                  showButton
                  buttonLabel="Aplicar"
                  onChange={handleChangeVoucher}
                  onClick={() => {
                    if (onApplyCoupon) {
                      onApplyCoupon(voucher);
                    }
                  }}
                  error={!!voucherError}
                  supportText={voucherError}
                />
              </FsTooltip>
            </S.TooltipWrapper>
          )}

          {products && products?.length !== 0 && (
            <FsAccordion label={`Produtos (${products?.length})`}>
              <Fragment>{products}</Fragment>
            </FsAccordion>
          )}
        </S.ContentWrapper>
      )}

      <S.ListContentContainer>
        <S.ListContentGroup>{items}</S.ListContentGroup>
        {total && (
          <FsListContentGhost textLeft="Total" textRight={total} bold />
        )}
      </S.ListContentContainer>

      {flag}
      {button}
    </S.Summary>
  );
}
