'use client';

import { createContext, useState } from 'react';
import {
  DrawerContextDrawer,
  DrawerContextProps,
  DrawerContextType,
} from './DrawerContext.types';

export const DrawerContext =
  createContext<DrawerContextType>(DrawerContextDrawer);

export const DrawerContextProvider = ({ children }: DrawerContextProps) => {
  const [
    isOpenDrawerPersonalizationHireDesigner,
    setIsOpenDrawerPersonalizationHireDesigner,
  ] = useState(false);
  const [
    isOpenDrawerPersonalizationPayment,
    setIsOpenDrawerPersonalizationPayment,
  ] = useState(false);
  const [
    isOpenDrawerPersonalizationTemplates,
    setIsOpenDrawerPersonalizationTemplates,
  ] = useState(false);

  const openDrawerPersonalizationHireDesigner = () =>
    setIsOpenDrawerPersonalizationHireDesigner(true);
  const closeDrawerPersonalizationHireDesigner = () =>
    setIsOpenDrawerPersonalizationHireDesigner(false);

  const openDrawerPersonalizationPayment = () =>
    setIsOpenDrawerPersonalizationPayment(true);
  const closeDrawerPersonalizationPayment = () =>
    setIsOpenDrawerPersonalizationPayment(false);

  const openDrawerPersonalizationTemplates = () =>
    setIsOpenDrawerPersonalizationTemplates(true);
  const closeDrawerPersonalizationTemplates = () =>
    setIsOpenDrawerPersonalizationTemplates(false);

  return (
    <DrawerContext.Provider
      value={{
        isOpenDrawerPersonalizationHireDesigner,
        openDrawerPersonalizationHireDesigner,
        closeDrawerPersonalizationHireDesigner,
        isOpenDrawerPersonalizationPayment,
        openDrawerPersonalizationPayment,
        closeDrawerPersonalizationPayment,
        isOpenDrawerPersonalizationTemplates,
        openDrawerPersonalizationTemplates,
        closeDrawerPersonalizationTemplates,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
