'use client';

import { Fragment, ReactNode } from 'react';
import { useLayout } from '../Media/Layout';

type MobileViewProps = {
  children: ReactNode;
};

export const MobileView = ({ children }: MobileViewProps) => {
  const { isMobile } = useLayout();

  return isMobile ? <Fragment>{children}</Fragment> : null;
};
