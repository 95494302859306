/* eslint-disable no-useless-escape */
'use client';

import React from 'react';
import Script from 'next/script';
import { NewRelicStaging } from './script-staging';
import { NewRelicProd } from './script-prod';

export function NewRelicScripts() {
  const renderScript =
    process.env.NODE_ENV === 'development' ? NewRelicStaging : NewRelicProd;
  return (
    <Script
      id="new-relic"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        ${renderScript}
        `,
      }}
    />
  );
}
