export enum ModalListEnum {
  NONE = '',
  PERSONALIZATION_OUT = 'PERSONALIZATION_OUT',
  PERSONALIZATION_HIRING_CONFIRMATION = 'PERSONALIZATION_HIRING_CONFIRMATION',
}

export type openModalProps = {
  callback?: () => void;
  modal?: ModalListEnum;
  param?: string;
};

export const ModalContextDefault = {
  isOpen: false,
  modalParam: '',
  currentModal: '',
  isLoadingPrimaryButtonModal: false,
  loadingPrimaryStart: () => {},
  loadingPrimaryStop: () => {},
  openModal: () => {},
  closeModal: () => {},
  runModalCallback: () => {},
};

export type ModalContextType = {
  isOpen: boolean;
  currentModal: string;
  isLoadingPrimaryButtonModal: boolean;
  loadingPrimaryStart: () => void;
  loadingPrimaryStop: () => void;
  modalParam: string;
  openModal: ({ callback, modal, param }: openModalProps) => void;
  closeModal: () => void;
  runModalCallback: () => void;
};
