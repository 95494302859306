'use client';

import { createContext, useContext, useState } from 'react';
import {
  PromotionsContextProps,
  PromotionsContextProviderProps,
  TDrawerCoupon,
} from './promotions.types';

const PromotionsContext = createContext<PromotionsContextProps | undefined>(
  undefined,
);

export const PromotionsContextProvider = ({
  children,
}: PromotionsContextProviderProps) => {
  const [drawerCoupon, setDrawerCoupon] = useState<TDrawerCoupon>({
    code: '',
    title: '',
    description: '',
    isOpen: false,
    onClose: () => {},
  });

  return (
    <PromotionsContext.Provider value={{ drawerCoupon, setDrawerCoupon }}>
      {children}
    </PromotionsContext.Provider>
  );
};

export const usePromotions = (): PromotionsContextProps => {
  const context = useContext(PromotionsContext);

  if (!context) {
    throw new Error(
      'usePromotions must be used within an PromotionsContextProvider',
    );
  }

  return context;
};
