'use client';

import { createContext, useState } from 'react';
import {
  ModalContextDefault,
  ModalContextType,
  ModalListEnum,
  openModalProps,
} from './ModalContext.types';

const sampleCallback = () => () => {
  alert('default modal callback click');
};

export const ModalContext =
  createContext<ModalContextType>(ModalContextDefault);

export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [callback, setCallback] = useState(sampleCallback);
  const [modalParam, setModalParam] = useState('');
  const [currentModal, setCurrentModal] = useState<ModalListEnum>(
    ModalListEnum.NONE,
  );
  const [isLoadingPrimaryButtonModal, setIsLoadingPrimaryButtonModal] =
    useState(false);

  const loadingPrimaryStart = () => setIsLoadingPrimaryButtonModal(true);
  const loadingPrimaryStop = () => setIsLoadingPrimaryButtonModal(false);

  const openModal = ({
    callback = sampleCallback,
    param = '',
    modal = ModalListEnum.NONE,
  }: openModalProps) => {
    setModalParam(param);
    setCallback(callback);
    setCurrentModal(modal);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalParam('');
    setCurrentModal(ModalListEnum.NONE);
  };

  const runModalCallback = () => {
    callback();
    closeModal();
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalParam,
        currentModal,
        isLoadingPrimaryButtonModal,
        loadingPrimaryStart,
        loadingPrimaryStop,
        openModal,
        closeModal,
        runModalCallback,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
